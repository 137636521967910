import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/components/ui/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/app/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
