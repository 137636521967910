'use client'

import Link from 'next/link'
import { useAuth } from '@clerk/nextjs'
import { useState, useEffect } from 'react'

export function Footer() {
  const { isSignedIn } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [hasStripeCustomer, setHasStripeCustomer] = useState(false)

  useEffect(() => {
    if (isSignedIn) {
      fetch('/api/user-profile')
        .then(res => res.json())
        .then(data => {
          setHasStripeCustomer(!!data.stripe_customer_id)
        })
        .catch(error => {
          console.error('Error fetching user profile:', error)
        })
    }
  }, [isSignedIn])

  const handleManageSubscription = async () => {
    try {
      setIsLoading(true)
      const response = await fetch('/api/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error('Failed to create portal session')
      const { url } = await response.json()
      window.location.href = url
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <footer className="bg-gradient-to-r from-blue-700 via-blue-600 to-blue-700 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
              Company
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link href="/about" className="text-base text-white hover:text-blue-100">
                  About
                </Link>
              </li>
              <li>
                <Link href="/pricing" className="text-base text-white hover:text-blue-100">
                  Pricing
                </Link>
              </li>
              <li>
                <Link href="/support" className="text-base text-white hover:text-blue-100">
                  Support
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
              Account
            </h3>
            <ul className="mt-4 space-y-4">
              {isSignedIn && hasStripeCustomer && (
                <li>
                  <button
                    onClick={handleManageSubscription}
                    disabled={isLoading}
                    className="text-base text-white hover:text-blue-100 cursor-pointer disabled:opacity-50"
                  >
                    {isLoading ? 'Loading...' : 'Manage Subscription'}
                  </button>
                </li>
              )}
              <li>
                <Link href="/privacy" className="text-base text-white hover:text-blue-100">
                  Privacy
                </Link>
              </li>
              <li>
                <Link href="/terms" className="text-base text-white hover:text-blue-100">
                  Terms
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
              Contact
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <a 
                  href="mailto:contact@analyzer.cv" 
                  className="text-base text-white hover:text-blue-100"
                >
                  contact@analyzer.cv
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 border-t border-blue-500 pt-8">
          <p className="text-base text-white text-center">
            &copy; {new Date().getFullYear()} Analyzer.CV. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}